import HeroRuleOne from "./HeroRuleOne";
import HeroRuleTwo from "./HeroRuleTwo";
import HeroRuleThree from "./HeroRuleThree";
import HeroCarousel from "./HeroCarousel";
import {JSX, lazy, useEffect, useState} from "react";
import GamesLayout from "../GamesLayout/GamesLayout";

const LiveAndUpcomingMatches = lazy(() => import("../LiveAndUpcomingMatches"));
const LatestVideos = lazy(() => import("../Media/LatestVideos"));
const LatestPodcasts = lazy(() => import("../Media/LatestPodcasts"));

export const HomePage = (): JSX.Element => {
    const temporaryFlag = false; // TODO: Added for this release only

    const wsUrl = process.env.FEED_URL;
    const apiKey = process.env.DECIMAL_API_KEY;
    const [teams, setTeams] = useState(["", ""]);
    const [batting, setBatting] = useState(null);
    const [score, setScore] = useState(["0/0", "0/0"]);
    const [overs, setOvers] = useState([""]);
    const [probability, setProbability] = useState([
        {name: "", probability: 0},
        {name: "", probability: 0},
    ]);
    const [scoregrid, setScoregrid] = useState([""]);
    const [partnership, setPartnership] = useState({});
    const [prematch, setPrematch] = useState(true);
    const [CurPos, setCurPos] = useState([0, 0, 0]);
    const [matchName, setMatchName] = useState("");
    const [location, setLocation] = useState();
    const [tournament, setTournament] = useState("");
    const [game_phase, setGamePhase] = useState("");
    const [hasLiveMatch, setHasLiveMatch] = useState(false);
    const [matchId, setMatchId] = useState("");
    const [localTime, setLocalTime] = useState("");
    const [watchalong, setWatchalong] = useState(false);
    const [watchalongStart, setWatchAlongStart] = useState("");
    const [watchalongUrl, setWatchAlongUrl] = useState("");
    const [isTraderEnabled, setIsTraderEnabled] = useState(false);
    const [isQuickfireEnabled, setIsQuickfireEnabled] = useState(false);
    const [isSquaresEnabled, setIsSquaresEnabled] = useState(true);

    const [squaresForLiveMatch, setSquaresForLiveMatch] = useState(false);
    const [quickfireEnabled, setQuickfireEnabled] = useState({
        enabled: false,
        competitionToLoad: "",
    });

    const headers = {
        "Content-Type": "application/json",
    };
    headers["x-api-key"] = process.env.API_KEY;

    useEffect(() => {
        const updatePageTitle = () => {
            document.title = "Home | Cricket8";
        };
        updatePageTitle();
        return () => {
        };
    }, []);

    const fetch_live_match = async () => {
        const res = await fetch(
            process.env.API_URL +
            // "/fixturelist?match_id_contains=LIVE&match_in_progress=true&limit=1", // TODO: Needed for release, change this
            "/fixturelist?match_id_contains=LIVE&match_in_progress=true&limit=1&competition=Indian Premier League",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": process.env.API_KEY as string,
                },
            }
        );
        if (res.status !== 200) {
            console.error("not ok result");
            return;
        }

        const data = await res.json();
        const matches = data?.matches;
        if (!matches || !matches?.length) {
            console.info("no in progress matches found");
            return;
        }

        setHasLiveMatch(true);
        const match = matches[0];
        const teams = match?.name ? match.name.split(" v ") : ["", ""];
        setMatchId(match.id);
        setTeams(teams);
        setMatchName(match.name);
        fetchMatchDetail(match.id);
    };

    const playSquaresForThisMatch = async (match_id) => {
        const response = await fetch(
            `${process.env.API_URL}/squaresactivematches/${match_id}`,
            {
                method: "GET",
                headers: headers,
            }
        );
        if (response.status !== 200) {
            setSquaresForLiveMatch(false);
        }
        const data = await response.json();
        setSquaresForLiveMatch(data);
    };

    const areGamesEnabled = async () => {
        const response = await fetch(`${process.env.API_URL}/isgameenabled/ALL`, {
            method: "GET",
            headers: headers,
        });
        if (response.status !== 200) {
            return;
        }
        const data = await response.json();
        const traderIndex = data.findIndex((x) => x.game_name === "Trader");
        if (traderIndex > -1) {
            setIsTraderEnabled(data[traderIndex].is_enabled);
        }
        const squaresIndex = data.findIndex((x) => x.game_name === "Squares");
        if (squaresIndex > -1) {
            setIsSquaresEnabled(data[squaresIndex].is_enabled);
        }
        const quickfireIndex = data.findIndex((x) => x.game_name === "Quickfire");
        if (quickfireIndex > -1) {
            setIsQuickfireEnabled(data[quickfireIndex].is_enabled);
        }
    };

    const isQuickFireEnabled = async () => {
        const clientTimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const date = new Date();
        const clientDate = date.toISOString().split("T")[0];
        const response = await fetch(
            `${process.env.API_URL}/quickfire/enabled?clientTZ=${clientTimezoneName}&clientDate=${clientDate}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": process.env.API_KEY as string,
                },
            }
        );
        if (response.status === 200) {
            const res = await response.json();
            if (res.countQuestions > 0) {
                setQuickfireEnabled(res);
            }
        }
    };

    const fetch_watchalong = async () => {
        const response = await fetch(`${process.env.API_URL}/settings/watchalong`, {
            method: "GET",
            headers: headers,
        });
        if (response.status !== 200) {
            return;
        }
        const data = await response.json();
        if (!data || !data.upcoming) {
            return fetch_live_match();
        }
        const upcMatch = data.upcoming;
        let upcMatchId = upcMatch.match_id;
        setMatchId(upcMatchId);
        setWatchAlongUrl(upcMatch.url);
        if (upcMatch.url && upcMatch.url.length > 0) {
            setWatchalong(upcMatchId);
        }
        setTournament(upcMatch.competition);
        fetchMatchDetail(upcMatchId);
        const countdown = setInterval(function () {
            const matchStart = new Date(data.upcoming.start).getTime();
            const now = new Date().getTime();
            const timeLeft = matchStart - now;
            const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
                (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
            setWatchAlongStart(
                days.toString().padStart(2, "0") +
                "d " +
                hours.toString().padStart(2, "0") +
                "h " +
                minutes.toString().padStart(2, "0") +
                "m " +
                seconds.toString().padStart(2, "0") +
                "s "
            );
            if (timeLeft < 0) {
                clearInterval(countdown);
                setWatchAlongStart("");
            }
        }, 1000);
    };

    const fetchMatchDetail = async (match_id) => {
        if (!match_id) {
            console.warn("no match id to fetch match detail, not setting up sockets");
            return;
        }

        playSquaresForThisMatch(match_id);

        let socket;
        if (process.env.ENVIRONMENT !== "dev") {
            socket = new WebSocket(`${wsUrl}`);
        } else {
            socket = new WebSocket(
                `${wsUrl}/${match_id}/${apiKey}/APIFEED-${match_id}`
            );
        }
        socket.addEventListener("open", function open() {
            const message = JSON.stringify({
                feed_filter: ["event", "market_price_update"],
                msg_type: "feed_subscription",
                feed_id: match_id,
            });
            socket.send(message);
        });

        socket.addEventListener("message", function incoming(data) {
            const parsedData = JSON.parse(data.data);
            if (parsedData.market_price_update) {
                const winPredict = parsedData.market_price_update.markets.find(
                    (market) => market.market_id === "1010"
                );
                if (winPredict) {
                    let prob1 = Math.round(winPredict.selections[0].probability * 100);
                    let prob2 = 100 - prob1;
                    setProbability([
                        {name: winPredict.selections[0].name, probability: prob1},
                        {name: winPredict.selections[1].name, probability: prob2},
                    ]);
                }
            }
            if (parsedData.event) {
                if (parsedData.event.event_description.includes("Pre Match Service")) {
                    if (parsedData.event.scoreboard) {
                        const scoreboard = parsedData.event.scoreboard;
                        const homeObj = scoreboard.find((item) => item.name === "home");
                        const awayObj = scoreboard.find((item) => item.name === "away");
                        setTeams([
                            homeObj ? homeObj.value : "",
                            awayObj ? awayObj.value : "",
                        ]);
                    }
                    if (!game_phase) {
                        setGamePhase("Scheduled");
                    }
                } else {
                    setPrematch(false);
                    const scoreboard = parsedData.event.scoreboard;
                    const matchTitleObj = scoreboard.find(
                        (item) => item.name === "matchtitle"
                    );
                    const game_phaseObj = scoreboard.find(
                        (item) => item.name === "game_phase"
                    );
                    const local_timeObj = scoreboard.find(
                        (item) => item.name === "local_time"
                    );
                    const homeObj = scoreboard.find((item) => item.name === "home");
                    const awayObj = scoreboard.find((item) => item.name === "away");
                    const battingObj = scoreboard.find(
                        (item) => item.name === "Current Batting Team"
                    );

                    let localdt = new Date(local_timeObj ? local_timeObj.value : "");
                    let formattedDate =
                        localdt.getDate().toString().padStart(2, "0") +
                        "/" +
                        (localdt.getMonth() + 1).toString().padStart(2, "0") +
                        "/" +
                        localdt.getFullYear();

                    let matchname = matchTitleObj ? matchTitleObj.value : "";
                    const parts = matchname.split(",");
                    if (parts.length > 2) {
                        setMatchName(parts[0].trim());
                        setLocation(parts[2].trim());
                        setTournament(parts[1].trim());
                    }
                    setGamePhase(game_phaseObj ? game_phaseObj.value : "");
                    setLocalTime(formattedDate);
                    setTeams([
                        homeObj ? homeObj.value : "",
                        awayObj ? awayObj.value : "",
                    ]);
                    setBatting(battingObj ? battingObj.value : "");

                    const NewPos = [0, 0, 0];
                    const CurInns = scoreboard.find(
                        (item) => item.name === "Current Innings"
                    );
                    NewPos[0] = CurInns ? CurInns.value : NewPos[0];
                    const CurOvrs = scoreboard.find(
                        (item) => item.name === "Current Innings Overs"
                    );
                    NewPos[1] = CurOvrs ? CurOvrs.value : NewPos[1];
                    const CurPart = scoreboard.find(
                        (item) => item.name === "Current Innings Part Overs"
                    );
                    NewPos[2] = CurOvrs ? CurPart.value : NewPos[2];
                    if (CurPos !== NewPos) {
                        //fetchScoreGrid(match_id);
                        setCurPos(NewPos);
                    }
                    const maxOvers = scoreboard.find((item) => item.name === "Max Overs");
                    const batting1 = scoreboard.find((item) => item.name === "batting.1");
                    const batting1Runs = scoreboard.find(
                        (item) => item.name === "runs.1"
                    );
                    const batting1Wkts = scoreboard.find(
                        (item) => item.name === "wkts.1"
                    );
                    const batting1MaxOvers = scoreboard.find(
                        (item) => item.name === "max.1"
                    );
                    const batting1Overs = scoreboard.find(
                        (item) => item.name === "overs.1"
                    );

                    const batting2 = scoreboard.find((item) => item.name === "batting.2");
                    const batting2Runs = scoreboard.find(
                        (item) => item.name === "runs.2"
                    );
                    const batting2Wkts = scoreboard.find(
                        (item) => item.name === "wkts.2"
                    );
                    const batting2MaxOvers = scoreboard.find(
                        (item) => item.name === "max.2"
                    );
                    const batting2Overs = scoreboard.find(
                        (item) => item.name === "overs.2"
                    );

                    let b1mover = batting1MaxOvers ? batting1MaxOvers.value : "";
                    let b2mover = batting2MaxOvers ? batting2MaxOvers.value : "";
                    let b1over = batting1Overs ? batting1Overs.value : "";
                    let b2over = batting2Overs ? batting2Overs.value : "";

                    let homeOver = b1over + "/" + b1mover;
                    if (b1over === b1mover) {
                        homeOver = b1mover;
                    }
                    let awayOver = b2over + "/" + b2mover;

                    let bat1Runs = batting1Runs ? batting1Runs.value : "";
                    let bat1Wkts = batting1Wkts ? batting1Wkts.value : "";
                    let bat2Runs = batting2Runs ? batting2Runs.value : "";
                    let bat2Wkts = batting2Wkts ? batting2Wkts.value : "";

                    if (b2over.value === b2mover) {
                        awayOver = b2mover;
                    }
                    if (awayOver === "") {
                        awayOver = "0/" + maxOvers.value;
                    }
                    let bat1Prog = bat1Runs + "/" + bat1Wkts;
                    if (bat1Prog === "/") {
                        bat1Prog = "0/0";
                    }
                    let bat2Prog = bat2Runs + "/" + bat2Wkts;
                    if (bat2Prog === "/") {
                        bat2Prog = "0/0";
                    }
                    if (batting1 ? batting1.value : "" === homeObj.value) {
                        setScore([bat1Prog, bat2Prog]);
                        setOvers([homeOver, awayOver]);
                    } else {
                        setScore([bat2Prog, bat1Prog]);
                        setOvers([awayOver, homeOver]);
                    }

                    const bat1 = scoreboard.find((item) =>
                        item.name === battingObj ? battingObj.value : "" + "Bat.1"
                    );
                    const bat2 = scoreboard.find((item) =>
                        item.name === battingObj ? battingObj.value : "" + "Bat.2"
                    );
                    const strike1 = scoreboard.find(
                        (item) => item.name === "Batsman1 Strike"
                    );
                    const strike2 = scoreboard.find(
                        (item) => item.name === "Batsman2 Strike"
                    );
                    let bowl1 = scoreboard.find((item) => item.name === "bowler1name");
                    let bowl2 = scoreboard.find((item) => item.name === "bowler2name");
                    const runs1 = scoreboard.find(
                        (item) => item.name === "Batsman1 Runs"
                    );
                    const runs2 = scoreboard.find(
                        (item) => item.name === "Batsman2 Runs"
                    );
                    let bowl1overs = scoreboard.find(
                        (item) => item.name === "bowler1overs"
                    );
                    let bowl1runs = scoreboard.find(
                        (item) => item.name === "bowler1runs"
                    );
                    let bowl1wicks = scoreboard.find(
                        (item) => item.name === "bowler1wickets"
                    );
                    let bowl2overs = scoreboard.find(
                        (item) => item.name === "bowler2overs"
                    );
                    let bowl2runs = scoreboard.find(
                        (item) => item.name === "bowler2runs"
                    );
                    let bowl2wicks = scoreboard.find(
                        (item) => item.name === "bowler2wickets"
                    );
                    const balls1 = scoreboard.find((item) =>
                        item.name === battingObj ? battingObj.value : "" + "Bat.1balls"
                    );
                    const balls2 = scoreboard.find((item) =>
                        item.name === battingObj ? battingObj.value : "" + "Bat.2balls"
                    );
                    let strikerbatter = "";
                    let batterruns = 0;
                    let batterballs = 0;
                    if (strike1 && strike1.value === "1") {
                        strikerbatter = bat1 ? bat1.value : 0;
                        batterruns = runs1.value;
                        batterballs = balls1.value;
                    } else {
                        strikerbatter = bat2 ? bat2.value : 0;
                        batterruns = runs2 ? runs2.value : 0;
                        batterballs = balls2 ? balls2.value : 0;
                    }
                    let actbowl = scoreboard.find((item) => item.name === "bowler");
                    let bowlovers = "0 overs";
                    let bowlruns = "0";
                    let bowlwickets = "0";
                    if (bowl1 ? bowl1.value : "" !== "") {
                        actbowl = bowl1.value;
                        bowlovers = bowl1overs.value + " overs";
                        bowlruns = bowl1runs.value;
                        bowlwickets = bowl1wicks.value;
                    } else {
                        actbowl = bowl2 ? bowl2.value : "";
                        bowlovers = bowl2overs ? bowl2overs.value : "" + " overs";
                        bowlruns = bowl2runs ? bowl2runs.value : "";
                        bowlwickets = bowl2wicks ? bowl2wicks.value : "";
                    }
                    setPartnership({
                        bowler: actbowl,
                        bowlerover: bowlovers,
                        bowlerruns: bowlruns,
                        bowlerwickets: bowlwickets,
                        batter: strikerbatter,
                        batterruns: batterruns,
                        batterballs: batterballs,
                    });
                }
            }
        });

        socket.addEventListener("error", function error(error) {
            console.error("WebSocket error:", error);
        });

        socket.addEventListener("close", function close(event) {
            console.log("Disconnected from the WebSocket server:", event);
            console.log(`Close code: ${event.code}, reason: ${event.reason}`);
        });
    };

    useEffect(() => {
        isQuickFireEnabled();
        areGamesEnabled().then(() => {
            fetch_watchalong();
        });
    }, []);

    return (
        <div className="content-t">
            {watchalong ? (
                <div id="content" className="w-full">
                    <HeroRuleOne
                        matchId={watchalong}
                        teams={teams}
                        score={score}
                        overs={overs}
                        tournament={tournament}
                        game_phase={game_phase}
                        url={watchalongUrl}
                        watchalongStart={watchalongStart}
                        isSquaresEnabled={isSquaresEnabled}
                        squaresForLiveMatch={squaresForLiveMatch}
                        isTraderEnabled={isTraderEnabled}
                    />
                </div>
            ) : (
                <div id="content" className="w-full">
                    {/*{(isSquaresEnabled && squaresForLiveMatch) || isTraderEnabled ? (*/}
                    {/*TODO: removed for this release, replace with the code below*/}
                    {temporaryFlag ? (
                        <HeroRuleTwo
                            matchId={matchId}
                            teams={teams}
                            score={score}
                            overs={overs}
                            tournament={tournament}
                            isSquaresEnabled={isSquaresEnabled}
                            squaresForLiveMatch={squaresForLiveMatch}
                            isTraderEnabled={isTraderEnabled}
                        />
                    ) : game_phase === "In Play" ? (
                        <HeroRuleThree
                            matchId={matchId}
                            teams={teams}
                            score={score}
                            overs={overs}
                            tournament={tournament}
                        />
                    ) : (
                        <HeroCarousel
                            quickfireEnabled={quickfireEnabled}/>
                    )}
                </div>
            )}

            <div
                id="content"
                className={`diagonal-gradient ${
                    watchalong ? "mt-[5px]" : "mt-[5px] md:!mt-[5px]"
                } `}
            >
                <>
                    <div className="w-full">
                        <GamesLayout
                            isTraderEnabled={isTraderEnabled}
                            isSquaresEnabled={isSquaresEnabled}
                            isQuickfireEnabled={
                                quickfireEnabled.enabled && isQuickfireEnabled
                            }
                        />
                    </div>
                </>

                <div
                    id="livematches"
                    className="p-6 mt-[10px] bg-linear-gradient h-full lg:h-[460]"
                >
                    <div className="flex justify-content-between items-center">
                        <div className="flex-grow">
                            <h1 className="text-[30px] md:text-[40px] h-[60px]">MATCHES</h1>
                        </div>
                        <div className="hidden md:inline">
                            <a href="/match">
                                <div className="relative w-[150px] h-[50px] left-0 bg-lime">
                                    <div
                                        className="relative left-[2px] top-[14px] font-anton font-normal text-main text-[16px] !text-center tracking-[0] leading-[normal]">
                                        ALL MATCHES
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="mt-[10px] min-h-[303px]">
                        <LiveAndUpcomingMatches/>
                    </div>
                    <a className="md:hidden" href="/match">
                        <div className="md:hidden mt-[20px] w-[100%] h-[50px] bg-lime flex justify-center items-center">
                            <div className="!text-center pt-[6px] text-main anton font-normal ">
                                ALL MATCHES
                            </div>
                        </div>
                    </a>
                </div>

                <div
                    id="news"
                    className="p-6 mt-[10px] bg-linear-gradient h-full lg:h-[460]"
                >
                    <div className="flex justify-content-between">
                        <div className="flex-grow">
                            <h1 className="text-[30px] md:text-[40px]">LATEST VIDEOS</h1>
                        </div>
                        <a href="/videos">
                            <div
                                className="hidden lg:flex justify-center items-center text-main bg-lime w-[150px] h-[50px]">
                                All Videos
                            </div>
                        </a>
                    </div>
                    <div className="mt-[10px] min-h-[303px] ">
                        <LatestVideos maxVids={4} style="summary"/>
                    </div>
                    <a href="/videos">
                        <div
                            className="mt-2 lg:hidden flex justify-center items-center text-main bg-lime w-full h-[50px]">
                            All Videos
                        </div>
                    </a>
                </div>

                <div
                    id="news"
                    className="p-6 mt-[10px] bg-linear-gradient h-full lg:h-[600px]"
                >
                    <div className="flex justify-content-between">
                        <div className="flex-grow">
                            <h1 className="text-[30px] md:text-[40px]">LATEST PODCASTS</h1>
                        </div>
                        <a href="/podcasts">
                            <div
                                className="hidden lg:flex justify-center items-center text-main bg-lime w-[150px] h-[50px]">
                                All Podcasts
                            </div>
                        </a>
                    </div>
                    <div className="mt-[10px] min-h-[303px]">
                        <LatestPodcasts maxPods={4} style="summary"/>
                    </div>
                    <a href="/podcasts">
                        <div
                            className="mt-2 lg:hidden flex justify-center items-center text-main bg-lime w-full h-[50px]">
                            All Podcasts
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};
export default HomePage;
