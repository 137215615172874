import React, {useEffect, useState} from "react";
import GamesMobileLayout from "./GamesLayoutMobile";
import {Link} from "react-router-dom";

export default function GamesLayout({isTraderEnabled, isSquaresEnabled, isQuickfireEnabled}) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

    useEffect(() => {
        function checkWidth() {
            setIsMobile(window.innerWidth < 480);
        }

        window.addEventListener("resize", checkWidth);
        return () => window.removeEventListener("resize", checkWidth);
    }, []);

    return (
        <>
            {isMobile ? (
                <div>
                    <GamesMobileLayout
                        isSquaresEnabled={isSquaresEnabled}
                        isTraderEnabled={isTraderEnabled}
                        isQuickfireEnabled={isQuickfireEnabled}/>
                </div>
            ) : (
                <>
                    <div className="bg-linear-gradient p-6 overflow-auto w-full">
                        <div className="flex justify-content-between items-center">
                            <div className="px-4 flex-grow">
                                <h1 className="text-[30px] md:text-[40px] h-[60px]">
                                    PLAY C8 GAMES
                                </h1>
                            </div>
                            <div className="hidden md:inline">
                                <a href="/games">
                                    <div className="relative w-[150px] h-[50px] left-0 bg-lime">
                                        <div
                                            className="relative left-[2px] top-[14px] font-anton font-normal text-main text-[16px] !text-center tracking-[0] leading-[normal]">
                                            ALL GAMES
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>


                        <div className="grid w-full items-center gap-2 p-2 sm:grid-cols-2 lg:flex">

                            {isQuickfireEnabled && (
                                <div
                                    className="bg-mainB flex flex-col justify-start items-center mt-2 w-full h-[266px] p-2">
                                    <div
                                        style={{
                                            backgroundImage: "url('/img/public/quickfire2.png')",
                                        }}
                                        className=" w-full h-[120px] p-2 flex flex-row justify-between"
                                    >
                                        <div>
                                            <img src="../img/public/quickfire.png"/>
                                            <p className="font-anton text-lime text-[30px] mt-2">
                                                QUICKFIRE
                                            </p>
                                        </div>
                                        <div className="mr-[-30px] m-[-40px]">
                                            <img src="../img/public/quickfire3.png"/>
                                        </div>
                                    </div>
                                    <div
                                        className="h-[60px] mt-2 text-[14px]  font-chakra text-white normal-case leading-[20px] font-normal tracking-[0]">
                                        Play against the Games Master and test your domestic and
                                        international cricket knowledge.
                                    </div>
                                    <Link className="w-full" to="/games">
                                        <div
                                            className="mt-2 flex justify-center items-center w-full h-[40px] text-main font-anton text-[16px] bg-lime text-center">
                                            PLAY QUICKFIRE QS
                                        </div>
                                    </Link>
                                </div>
                            )}

                            <div className="flex flex-col justify-start items-center w-full h-[266px] p-2">
                                <div
                                    className="relative w-full h-[220px] mt-[-19px]"
                                    style={{
                                        backgroundImage: "url('/img/public/spiner.png')",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "center",
                                    }}
                                >
                                    <img
                                        src="../img/public/spin-coins.png"
                                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                    />
                                </div>
                                <Link to="/games" className="w-full min-h-[40px]">
                                    <div
                                        className="flex justify-center items-center w-full min-h-[40px] text-main font-anton
                  text-[16px] bg-lime text-center"
                                    >
                                        SPIN FOR FREE COINS
                                    </div>
                                </Link>
                            </div>

                            {isTraderEnabled && (
                                <div
                                    className="bg-mainB flex flex-col justify-start items-center mt-2 w-full h-[266px] p-2 opacity-70">
                                    <div
                                        style={{backgroundImage: "url('/img/public/trade1.png')"}}
                                        className=" w-full h-[120px] p-2  flex flex-row justify-between"
                                    >
                                        <div>
                                            <img src="../img/public/group.png"/>
                                            <p className="font-anton text-lime text-[30px] mt-2">
                                                Trader
                                            </p>
                                        </div>
                                        <div className="mr-[-30px] mt-[-45px]">
                                            <img src="../img/public/trader2.png"/>
                                        </div>
                                    </div>
                                    <div
                                        className="mt-2 text-[14px] h-[60px] font-chakra text-white normal-case leading-[20px] font-normal tracking-[0]">
                                        Trade live matches on winner, run lines, fancies and more.
                                        Find your edge and press for profit.
                                    </div>
                                    {/*<Link className="w-full" to="/trader">*/}
                                    {/*    <div*/}
                                    {/*        className="mt-2 flex justify-center items-center w-full h-[40px] text-main font-anton text-[16px] bg-lime text-center">*/}
                                    {/*        PLAY TRADER*/}
                                    {/*    </div>*/}
                                    {/*</Link>*/}

                                    {/*TODO: added for this release only, update with the code above*/}
                                    <div
                                        className="mt-2 flex justify-center items-center w-full h-[40px] text-main font-anton text-[16px] bg-white text-center">
                                        COMING SOON
                                    </div>
                                </div>
                            )}

                            {isSquaresEnabled && (
                                <div
                                    className="bg-mainB flex flex-col justify-start items-center mt-2 w-full h-[266px] p-2 opacity-70">
                                    <div
                                        style={{
                                            backgroundImage: "url('/img/public/squares2.png')",
                                        }}
                                        className=" w-full h-[120px] p-2 flex flex-row justify-between"
                                    >
                                        <div>
                                            <img src="../img/public/squares.png"/>
                                            <p className="font-anton text-lime text-[30px] mt-2">
                                                SQUARES
                                            </p>
                                        </div>

                                        <div className="mr-[-30px] m-[-10px]">
                                            <img src="../img/public/squares3.png"/>
                                        </div>
                                    </div>
                                    <div
                                        className="h-[60px] mt-2 text-[14px] font-chakra text-white normal-case leading-[20px] font-normal tracking-[0]">
                                        Live match ball-by-ball prediction for every over. Vs friends
                                        and pros.
                                    </div>
                                    {/*<Link className="w-full" to="/squares">*/}
                                    {/*    <div*/}
                                    {/*        className="mt-2 flex justify-center items-center w-full h-[40px] text-main font-anton text-[16px] bg-lime text-center">*/}
                                    {/*        PLAY SQUARES*/}
                                    {/*    </div>*/}
                                    {/*</Link>*/}

                                    {/*TODO: added for this release only, update with the code above*/}
                                    <div
                                        className="mt-2 flex justify-center items-center w-full h-[40px] text-main font-anton text-[16px] bg-white text-center">
                                        COMING SOON
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </>
            )}
        </>
    );
}
