import ClubLogo from "../ClubLogo";
import { Link } from "react-router-dom";
import React from "react";

export default function HeroRuleThree({
  matchId,
  teams,
  score,
  overs,
  tournament,
}) {
  return (
    <div className="mb-[40px]">
      <div className="flex my-[15px]">
        <div className="flex flex-row justify-between items-center w-full lg:mt-0 md:mt-0 w-full px-4 lg:px-12">
          <div>
            <p className="font-antot text-[16px] text-center md:text-start lg:text-start lg:text-[40px] leading-[25px] uppercase">
              The home of cricket{" "}
              <span className="text-lime uppercase">livestream </span>
              media and <span className="text-lime uppercase"> games</span>
            </p>
          </div>
        </div>
        <div className="mt-[-31px] hidden lg:flex justify-end">
          <Link to={"/games"}>
            <img
              className=" w-[120px] h-[120px]"
              src="../img/public/spin-for-coins.png"
            />
          </Link>
        </div>
      </div>
      <div className="flex flex-col justify-center gap-4 px-4 lg:px-10">
        <div className="w-full bg-linear-gradient h-[530px] lg:h-[385px] flex flex-col lg:flex-row justify-center items-center gap-4">
          <div className="w-full lg:w-full lg:h-[289px] flex flex-col justify-start gap-4 p-2">
            <div className="flex flex-row justify-start gap-2">
              <div className="flex justify-center items-center h-[38px] w-[120px] text-[14px] lg:text-[18px] leading-[18px] bg-matchRed">
                Match in-Play
              </div>
              <div className="flex justify-center items-center font-chakra text-[14px] lg:text-[16px] leading-[20px] normal-case">
                {tournament}
              </div>
            </div>
            <div className="flex flex-col justify-center items-center gap-4 lg:gap-2 lg:h-[190px]">
              <div className="w-full bg-mainF h-[60px] flex flex-row justify-between items-center">
                <div className="gap-2 flex flex-row justify-center items-center">
                  <div className="w-[60px] h-[60px] flex justify-center items-center bg-white">
                    <ClubLogo height="55px" width="55px" teamName={teams[0]} />
                  </div>
                  {teams && (
                    <p className="text-[16px] lg:text-[26px] leading-[15px]">
                      {teams[0]}
                    </p>
                  )}
                </div>
                <div className="flex flex-col justify-center items-end gap-1 px-2">
                  <p className="text-[16px] lg:text-[20px] leading-[15px]">
                    {score[0]}
                  </p>
                  <p className="font-chakra text-[12px] leading-[15px] normal-case opacity-50">
                    {overs[0]} overs
                  </p>
                </div>
              </div>
              <div className="w-full bg-mainF h-[60px] flex flex-row justify-between items-center">
                <div className="gap-2 flex flex-row justify-center items-center">
                  <div className="w-[60px] h-[60px] flex justify-center items-center bg-white">
                    <ClubLogo height="55px" width="55px" teamName={teams[1]} />
                  </div>
                  {teams && (
                    <p className="text-[16px] lg:text-[26px] leading-[15px]">
                      {teams[1]}
                    </p>
                  )}
                </div>
                <div className="flex flex-col justify-center items-end gap-1 px-2">
                  <p className="text-[16px] lg:text-[20px] leading-[15px]">
                    {score[1]}
                  </p>
                  <p className="font-chakra text-[12px] leading-[15px] normal-case opacity-50">
                    {overs[1]} overs
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-full lg:h-[289px] flex flex-col justify-start gap-4 p-2">
            <div className="hidden lg:flex flex-row justify-start gap-2">
              <div className="hidden lg:flex justify-center items-center h-[38px] w-[120px] text-[18px] leading-[18px]"></div>
              <div className="hidden lg:flex justify-center items-center font-chakra text-[16px] leading-[20px] normal-case"></div>
            </div>
            <div className="flex flex-col justify-center items-center gap-2 h-[190px]">
              <div className="w-full h-[190px] flex flex-col justify-center items-center gap-4">
                <div className="w-full flex flex-row justify-center items-center gap-2">
                  <div className="w-full text-[14px] lg:text-[20px] h-[70px] bg-mainB flex justify-center items-center">
                    <a href={`/matchdetail?id=${matchId}&selected=commentary`}>
                      🎙️ Commentary
                    </a>
                  </div>
                  <div className="w-full text-[14px] lg:text-[20px] h-[70px] bg-mainB flex justify-center items-center">
                    <a href={`/matchdetail?id=${matchId}&selected=scorecard`}>
                      🏏 Scorecard
                    </a>
                  </div>
                  <div className="w-full text-[14px] lg:text-[20px] h-[70px] bg-mainB flex justify-center items-center">
                    <a href={`/matchdetail?id=${matchId}&selected=lineup`}>
                      📄 Lineups
                    </a>
                  </div>
                </div>
                <div className="flex justify-center items-center w-full h-[40px] bg-lime text-main">
                  <a href={`/matchdetail?id=${matchId}`}>Go to Match</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
