import {Link} from "react-router-dom";

interface CardsProps {
    topBtn: string;
    description: string;
    text: string;
    bottomBtn: string;
    imgSrcBottom: string;
    classForImg: string;
    backgroundImg?: string;
    href: string;
}

export default function HeroCarouselItem({
                                             topBtn,
                                             description,
                                             text,
                                             bottomBtn,
                                             imgSrcBottom,
                                             backgroundImg,
                                             classForImg,
                                             href,
                                         }: CardsProps) {
    return (
        <div className="flex flex-col justify-start gap-4 p-4 mb-10">
            <div
                className="w-full lg:w-full bg-linear-gradient
        lg:h-[345px] lg:p-2 flex flex-col lg:flex-row justify-center items-center lg:gap-4"
            >
                <div
                    className="w-full h-[170px] lg:h-[345px] flex flex-col justify-between lg:justify-around items-center lg:items-start lg:gap-4">
                    <div className="flex justify-center items-center w-[60px] !h-[30px] bg-lime text-main text-[14px]">
                        {topBtn}
                    </div>
                    <div className="text-center lg:text-start">
                        <p
                            className="font-400 text-[23px] p-2 lg:p-0 leading-[25px] lg:leading-[40px] lg:text-[40px]
            xl-[text-50px] xl:leading-[50px]"
                        >
                            {description}
                        </p>
                        <p className="text-[14px] lg:text-[16px] font-chakra normal-case">
                            {text}
                        </p>
                    </div>
                    <div>
                        <Link
                            className="pointer hidden lg:flex flex justify-center items-center w-[200px] h-[40px] bg-lime text-main text-[16]px"
                            to={href}
                        >
                            {bottomBtn}
                        </Link>
                    </div>
                </div>
                <div
                    style={{
                        backgroundImage: `${backgroundImg}`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                    className="w-full lg:w-[90%] lg:h-[345px] flex flex-col justify-center items-center
          lg:flex-row lg:justify-around lg:items-center p-2 lg:p-4 lg:gap-0 gap-2"
                >
                    <div>
                        <img className={classForImg} src={imgSrcBottom}/>
                    </div>
                    <Link
                        className="pointer lg:hidden flex justify-center items-center w-full h-[40px] bg-lime text-main text-[16px]"
                        to={href}
                    >
                        {bottomBtn}
                    </Link>
                </div>
            </div>
        </div>
    );
}
