import React, {useEffect, useState} from "react";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./indicator.css";
import {Link} from "react-router-dom";

export default function GamesMobileLayout({isTraderEnabled, isSquaresEnabled, isQuickfireEnabled}) {
    const defaultCarouselItems = [
        <div className="flex flex-col justify-start items-center w-full h-[266px] p-2">
            <div
                className="relative w-full h-[200px] mt-[-3px]"
                style={{
                    backgroundImage: "url('/img/public/spiner.png')",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    width: "217px",
                }}
            >
                <img
                    src="../img/public/spin-coins.png"
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
            </div>
            <Link className="w-full" to="/games">
                <div
                    className="flex justify-center items-center w-full min-h-[40px] text-main font-anton
            text-[16px] bg-lime text-center"
                >
                    SPIN FOR FREE COINS
                </div>
            </Link>
        </div>
    ];

    const [carouselItems, setCarouselItems] =
        useState<React.ReactElement[]>(defaultCarouselItems);

    useEffect(() => {
        const additionalCarouselItems: React.ReactElement[] = [];
        if (isQuickfireEnabled) {
            additionalCarouselItems.push(
                <div className="bg-mainB flex flex-col justify-start items-center w-full h-[266px] p-2">
                    <div
                        style={{
                            backgroundImage: "url('/img/public/quickfire2.png')",
                        }}
                        className=" w-full h-[120px] p-2 flex flex-row justify-between"
                    >
                        <div className="flex flex-col justify-start">
                            <img
                                className="!w-[40px] !h-[40px]"
                                src="../img/public/quickfire.png"
                            />
                            <p className="font-anton text-lime text-[30px] mt-2">QUICKFIRE</p>
                        </div>
                        <div className="mr-[-25px] m-[-25px]">
                            <img src="../img/public/quickfire3.png"/>
                        </div>
                    </div>
                    <div
                        className="h-[60px]  mt-2 text-[16px]  font-chakra text-white normal-case leading-[20px] font-normal tracking-[0]">
                        Play against the Games Master and test your domestic and
                        international cricket knowledge.
                    </div>
                    <Link className="w-full" to="/games">
                        <div
                            className="mt-2 flex justify-center items-center w-full h-[40px] text-main font-anton text-[16px] bg-lime text-center">
                            PLAY QUICKFIRE QS
                        </div>
                    </Link>
                </div>
            )
        }
        if (isTraderEnabled) {
            additionalCarouselItems.push(
                <div className="bg-mainB flex flex-col justify-start items-center w-full h-[266px] p-2 opacity-70">
                    <div
                        style={{backgroundImage: "url('/img/public/trade1.png')"}}
                        className=" w-full h-[120px] p-2  flex flex-row justify-between"
                    >
                        <div className="flex flex-col justify-start">
                            <img
                                className="!w-[40px] !h-[40px]"
                                src="../img/public/group.png"
                            />
                            <p className="font-anton text-lime text-[30px] mt-2">Trader</p>
                        </div>
                        <div className="mr-[-30px] mt-[-25px]">
                            <img src="../img/public/trader2.png"/>
                        </div>
                    </div>
                    <div
                        className="mt-2 text-[16px]  font-chakra text-white normal-case leading-[20px] font-normal tracking-[0]">
                        Trade live matches on winner, run lines, fancies and more. Find your
                        edge and press for profit.
                    </div>
                    {/*<Link className="w-full" to="/trader">*/}
                    {/*    <div*/}
                    {/*        className="mt-2 flex justify-center items-center w-full h-[40px] text-main font-anton text-[16px] bg-lime text-center">*/}
                    {/*        PLAY TRADER*/}
                    {/*    </div>*/}
                    {/*</Link>*/}
                    {/*TODO: added for this release only, update with the code above*/}
                    <div
                        className="mt-2 flex justify-center items-center w-full h-[40px] text-main font-anton text-[16px] bg-white text-center">
                        COMING SOON
                    </div>
                </div>
            );
        }
        if (isSquaresEnabled) {
            additionalCarouselItems.push(
                <div className="bg-mainB flex flex-col justify-start items-center w-full h-[266px] p-2 opacity-70">
                    <div
                        style={{
                            backgroundImage: "url('/img/public/squares2.png')",
                        }}
                        className=" w-full h-[120px] p-2 flex flex-row justify-between"
                    >
                        <div className="flex flex-col justify-start">
                            <img
                                className="!w-[40px] !h-[40px]"
                                src="../img/public/squares.png"
                            />
                            <p className="font-anton text-lime text-[30px] mt-2">SQUARES</p>
                        </div>

                        <div className="mr-[-25px] m-[-10px]">
                            <img src="../img/public/squares3.png"/>
                        </div>
                    </div>
                    <div
                        className="h-[60px] mt-2 text-[16px]  font-chakra text-white normal-case leading-[20px] font-normal tracking-[0]">
                        Live match ball-by-ball prediction for every over. Vs friends and
                        pros.
                    </div>
                    {/*<Link className="w-full" to="/squares">*/}
                    {/*    <div*/}
                    {/*        className="mt-2 flex justify-center items-center w-full h-[40px] text-main font-anton text-[16px] bg-lime text-center">*/}
                    {/*        PLAY SQUARES*/}
                    {/*    </div>*/}
                    {/*</Link>*/}
                    {/*TODO: added for this release only, update with the code above*/}
                    <div
                        className="mt-2 flex justify-center items-center w-full h-[40px] text-main font-anton text-[16px] bg-white text-center">
                        COMING SOON
                    </div>
                </div>
            );
        }
        setCarouselItems([...defaultCarouselItems, ...additionalCarouselItems]);
    }, [isSquaresEnabled, isTraderEnabled, isQuickfireEnabled]);



    return (
        <div className="p-4 bg-linear-gradient flex flex-col justify-center items-center gap-8">
            <div className="px-2 w-full flex justify-start">
                <h1 className="text-[30px]">Play C8 GAMES</h1>
            </div>
            <Carousel
                className="w-full overflow-visible relative"
                showThumbs={false}
                centerSlidePercentage={80}
                centerMode={true}
                showStatus={false}
                infiniteLoop={true}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={50}
                showArrows={false}
                renderItem={(slide, options) => {
                    const selected = options?.isSelected;
                    return (
                        <div className={`${selected ? "opacity-100" : "opacity-50"} px-1`}>
                            {slide}
                        </div>
                    );
                }}
            >
                {carouselItems.map((item, index) => {
                    return <div key={index}>{item}</div>;
                })}
            </Carousel>
            <div className="p-2 w-full my-4">
                <Link to={"/games"}>
                    <div
                        className="flex justify-center items-center w-full h-[50px] text-main font-anton text-[16px] bg-lime text-center">
                        ALL GAMES
                    </div>
                </Link>
            </div>
        </div>
    );
}
