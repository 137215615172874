import React, {useEffect, useState} from "react";
import {Carousel} from "react-responsive-carousel";
import {Link} from "react-router-dom";
import HeroCarouselItem from "./HeroCarouselItem";

export interface Podcast {
    media: string
    title: string
    date: string
}

export interface Video {
    media: string
    title: string
    date: string
}

const defaultCarouselItems = [
    <div>
        <HeroCarouselItem
            topBtn={"Games"}
            description={"Spin the wheel for free coins"}
            text={"Spin the wheel each day to earn free coins."}
            bottomBtn={"Spin for coins"}
            imgSrcBottom={"../img/public/spinner2.png"}
            classForImg={"w-[220px] h-[225px] lg:h-[430px] lg:w-full"}
            href={"/games"}
        />
    </div>
];


export default function HeroCarousel({quickfireEnabled}) {
    const [podcast, setPodcast] = useState<Podcast | null>(null);
    const [video, setVideo] = useState<Video | null>(null);
    const [carouselItems, setCarouselItems] =
        useState<React.ReactElement[]>(defaultCarouselItems);

    function decodeHtmlEntities(str) {
        var textArea = document.createElement("textarea");
        textArea.innerHTML = str;
        return textArea.value;
    }

    async function fetchVideo() {
        try {
            const res = await fetch(`${process.env.API_URL}/videos/1/0`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": process.env.API_KEY as string,
                },
            });
            if (res.status !== 200) {
                return;
            }
            const data = await res.json();
            if (!data?.videos?.length) {
                return;
            }
            const videoFromServer = data.videos[0];
            const videoItem = videoFromServer.item;
            let title = decodeHtmlEntities(videoItem.snippet.title);
            const dateObject = new Date(videoItem.snippet.publishedAt);
            let bigmedia = videoItem.snippet.thumbnails.high.url;
            let dte = dateObject.toLocaleDateString("en-US", {
                day: "2-digit",
                month: "long",
            });
            setVideo({
                title: title,
                media: bigmedia,
                date: dte,
            });
        } catch (error) {
            console.log(error);
        }
    }

    async function fetchPodcasts() {
        try {
            const res = await fetch(`${process.env.API_URL}/podcasts/1/0`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": process.env.API_KEY as string,
                },
            });
            if (res.status !== 200) {
                console.error("Failed to fetch podcasts:", res.status);
                return;
            }
            const data = await res.json();
            if (data.items.length > 0) {
                const podcastData = data.items[0];
                let title = decodeHtmlEntities(podcastData.name);
                let media = podcastData.images[1].url;
                const dateObject = new Date(podcastData.release_date);
                let dte = dateObject.toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "long",
                });
                setPodcast({
                    title,
                    media,
                    date: dte,
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchPodcasts();
        fetchVideo();
    }, []);

    useEffect(() => {
        const additionalCarouselItems: React.ReactElement[] = [];
        if (quickfireEnabled.enabled) {
            additionalCarouselItems.push(
                <div>
                    <HeroCarouselItem
                        topBtn={"Games"}
                        description={"Test your cricket knowledge"}
                        text={"Earn coins by correctly answering 20 trivia questions each day."}
                        imgSrcBottom={"../img/public/trivia-lg.png"}
                        bottomBtn={"Play Quickfire"}
                        backgroundImg="url('/img/public/trivia-question7.png')"
                        classForImg={"w-[220px] h-[220px] lg:w-[437px] lg:h-[370px]"}
                        href={"/games"}
                    />
                </div>
            )
        }
        if (video) {
            additionalCarouselItems.push(
                <div>
                    <HeroCarouselItem
                        topBtn={"Videos"}
                        description={video.title}
                        text={video.date}
                        bottomBtn={"All videos"}
                        href={"/videos"}
                        imgSrcBottom={video.media}
                        classForImg={"w-[220px] h-[220px] lg:w-[488px] lg:h-[275px]"}
                    />
                </div>
            );
        }
        if (podcast) {
            additionalCarouselItems.push(
                <div>
                    <HeroCarouselItem
                        topBtn={"podcast"}
                        description={podcast.title}
                        text={podcast.date}
                        bottomBtn={"All podcasts"}
                        href={"/podcasts"}
                        imgSrcBottom={"../img/public/podcast_card.png"}
                        classForImg={"w-[220px] h-[220px] lg:w-[275px] lg:h-[275px]"}
                    />
                </div>
            );
        }
        setCarouselItems([...defaultCarouselItems, ...additionalCarouselItems]);
    }, [podcast, video, quickfireEnabled]);

    return (
        <div>
            <div className="flex">
                <div className="flex justify-center items-center lg:justify-start lg:ml-12 mt-2 w-full">
                    <p className="font-antot text-[16px] lg:text-[40px] leading-[25px] uppercase">
                        The home of cricket
                        <span className="text-lime uppercase"> livestream </span>
                        media and <span className="text-lime uppercase"> games</span>
                    </p>
                </div>
                <div className="mt-[-15px] flex justify-end">
                    <Link to={"/games"}>
                        <img
                            className="!w-[120px] !h-[120px] hidden lg:flex"
                            src="../img/public/spin-for-coins.png"
                        />
                    </Link>
                </div>
            </div>
            <Carousel
                className="custom-carousel"
                showThumbs={false}
                autoPlay
                showStatus={false}
                centerMode={true}
                infiniteLoop={true}
                centerSlidePercentage={80}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={50}
                showArrows={false}
                renderItem={(slide, option) => {
                    const selected = option?.isSelected;

                    return (
                        <div className={`${selected ? "opacity-100" : "opacity-50"}`}>
                            {slide}
                        </div>
                    );
                }}
            >
                {carouselItems.map((item, index) => {
                    return <div key={index}>{item}</div>;
                })}
            </Carousel>
        </div>
    );
}
