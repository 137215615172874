import ClubLogo from "../ClubLogo";
import { Link } from "react-router-dom";
import React from "react";

export default function HeroRuleOne({
  matchId,
  teams,
  score,
  overs,
  tournament,
  game_phase,
  url,
  watchalongStart,
  isTraderEnabled,
  isSquaresEnabled,
  squaresForLiveMatch,
}) {

  isTraderEnabled = false; // TODO: added for this release only,
  isSquaresEnabled = false; // should be removed when games are available
  return (
    <div className="mb-[40px]">
      <div className="flex my-[15px]">
        <div className="flex flex-row justify-between items-center w-full lg:mt-0 md:mt-0 px-4 lg:px-12">
          <div>
            <p className="font-antot text-[16px] text-center md:text-start lg:text-start lg:text-[40px] leading-[25px] uppercase">
              The home of cricket{" "}
              <span className="text-lime uppercase">livestream </span>
              media and <span className="text-lime uppercase"> games</span>
            </p>
          </div>
        </div>
        <div className="hidden lg:mt-[-31px] lg:flex justify-end">
          <Link to={"/games"}>
            <img
              className=" w-[120px] h-[120px] "
              src="../img/public/spin-for-coins.png"
            />
          </Link>
        </div>
      </div>
      <div className="flex flex-col justify-start gap-4 px-4 lg:px-12">
        <div className="w-full lg:w-full bg-linear-gradient flex flex-row justify-center items-center gap-4 p-2">
          <div className="p-2 lg:p-0 w-full flex flex-col justify-start gap-4">
            <div className="flex flex-row justify-start gap-2">
              {game_phase && (
                <div className="flex justify-center items-center h-[30px] w-[100px] text-[12px] md:h-[38px] md:w-[140px] md:text-[16px] leading-[18px] bg-matchRed">
                  {watchalongStart?.length > 0 ? watchalongStart : game_phase}
                </div>
              )}
              <div className="flex justify-center items-center font-chakra text-[12px] md:text-[16px] leading-[20px] normal-case">
                {tournament}
              </div>
            </div>
            <div className="flex flex-col gap-1 lg:gap-2">
              <div className="w-full bg-mainF h-[60px] flex flex-row justify-between items-center">
                <div className="gap-2 flex flex-row justify-center items-center">
                  <div className="min-w-[60px] h-[60px] flex justify-center items-center bg-white">
                    <ClubLogo height="55px" width="55px" teamName={teams[0]} />
                  </div>
                  {teams && (
                    <p className="text-[16px] lg:text-[26px] leading-[15px]">
                      {teams[0]}
                    </p>
                  )}
                </div>
                <div className="flex flex-col justify-center items-end gap-1 px-2">
                  <p className="text-[20px] leading-[15px]">{score[0]}</p>
                  <p className="font-chakra text-[12px] leading-[15px] normal-case opacity-50">
                    {overs[0]} overs
                  </p>
                </div>
              </div>
              <div className="w-full bg-mainF h-[60px] flex flex-row justify-between items-center">
                <div className="gap-2 flex flex-row justify-center items-center">
                  <div className="min-w-[60px] h-[60px] flex justify-center items-center bg-white">
                    <ClubLogo height="55px" width="55px" teamName={teams[1]} />
                  </div>
                  {teams && (
                    <p className="text-[16px] lg:text-[26px] leading-[15px]">
                      {teams[1]}
                    </p>
                  )}
                </div>
                <div className="flex flex-col justify-center items-end gap-1 px-2">
                  <p className="text-[20px] leading-[15px]">{score[1]}</p>
                  <p className="font-chakra text-[12px] leading-[15px] normal-case opacity-50 text-end">
                    {overs[1]} overs
                  </p>
                </div>
              </div>
              <div
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  aspectRatio: "16/9",
                }}
                className="mt-2 lg:hidden flex"
              >
                <iframe
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  width="100%"
                  height="100%"
                  src={url}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-center items-center gap-2">
              {isTraderEnabled && (
                <Link className="w-full" to="/trader">
                  <div className="p-2 w-full h-[60px] lg:h-[70px] bg-mainB flex flex-row justify-between items-center">
                    <p>
                      Play this match <br />
                      on <span className="text-lime">trader</span>
                    </p>
                    <img
                      className="w-[90px] h-[83px]"
                      src="../img/public/trader2.png"
                    />
                  </div>
                </Link>
              )}
              {isSquaresEnabled && squaresForLiveMatch && (
                <Link className="w-full" to="/squares">
                  <div className="p-2 w-full h-[60px] lg:h-[70px] bg-mainB flex flex-row justify-between items-center">
                    <p>
                      Play this match <br />
                      on <span className="text-lime">squares</span>
                    </p>
                    <img
                      className="w-[105px] h-[55px]"
                      src="../img/public/squares3.png"
                    />
                  </div>
                </Link>
              )}
            </div>
            <div className="lg:mt-auto">
              <div className="flex justify-center items-center w-full h-[40px] bg-lime text-main">
                <a href={`/matchdetail?id=${matchId}`}>Go to Match</a>
              </div>
            </div>
          </div>
          <div
            className="w-full hidden lg:flex"
            style={{ aspectRatio: "16/9" }}
          >
            <iframe
              width="100%"
              height="100%"
              src={url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
