import { Link } from "react-router-dom";
import React from "react";
import ClubLogo from "../ClubLogo";

export default function HeroRuleTwo({
  matchId,
  teams,
  score,
  tournament,
  overs,
  isTraderEnabled,
  isSquaresEnabled,
  squaresForLiveMatch,
}) {

  isTraderEnabled = false; // TODO: added for this release only,
  isSquaresEnabled = false; // should be removed when games are available
  return (
    <div className="mb-[40px]">
      <div className="flex my-[15px]">
        <div className="flex flex-row justify-between items-center px-4 lg:px-12 lg:mt-0 md:mt-0 w-full">
          <div>
            <p className="font-antot text-[16px] text-center md:text-start lg:text-start lg:text-[40px] leading-[25px] uppercase">
              The home of cricket{" "}
              <span className="text-lime uppercase">livestream </span>
              media and <span className="text-lime uppercase"> games</span>
            </p>
          </div>
        </div>
        <div className="hidden mt-[-31px] lg:flex justify-end">
          <Link to={"/games"}>
            <img
              className=" w-[120px] h-[120px]"
              src="../img/public/spin-for-coins.png"
            />
          </Link>
        </div>
      </div>
      <div className="px-4 lg:px-12">
        <div
          className="w-full bg-linear-gradient h-[580px] lg:h-[289px]
        flex flex-col lg:flex lg:flex-row justify-center items-center gap-4"
        >
          <div className="w-full h-[210px] lg:h-[289px] flex flex-col justify-start gap-4 p-2">
            <div className="flex flex-row justify-start gap-2">
              <div className="flex justify-center items-center h-[38px] w-[120px] text-[14px] lg:text-[18px] leading-[18px] bg-matchRed">
                Match in-Play
              </div>
              <div className="flex justify-center items-center font-chakra text-[14px] lg:text-[16px] leading-[20px] normal-case">
                {tournament}
              </div>
            </div>
            <div className="flex flex-col gap-2 h-[190px]">
              <div className="w-full bg-mainF h-[60px] flex flex-row justify-between items-center">
                <div className="gap-2 flex flex-row justify-center items-center">
                  <div className="w-[60px] h-[60px] flex justify-center items-center bg-white">
                    <ClubLogo height="55px" width="55px" teamName={teams[0]} />
                  </div>
                  {teams && (
                    <p className="text-[16px] lg:text-[26px] leading-[15px]">
                      {teams[0]}
                    </p>
                  )}
                </div>
                <div className="flex flex-col justify-center items-center gap-1">
                  <p className="text-[20px] leading-[15px]">{score[0]}</p>
                  <p className="font-chakra text-[12px] leading-[15px] normal-case opacity-50">
                    {overs[0]} overs
                  </p>
                </div>
              </div>
              <div className="w-full bg-mainF h-[60px] flex flex-row justify-between items-center">
                <div className="gap-2 flex flex-row justify-center items-center">
                  <div className="w-[60px] h-[60px] flex justify-center items-center bg-white">
                    <ClubLogo height="55px" width="55px" teamName={teams[1]} />
                  </div>
                  {teams && (
                    <p className="text-[16px] lg:text-[26px] leading-[15px]">
                      {teams[1]}
                    </p>
                  )}
                </div>
                <div className="flex flex-col justify-center items-center gap-1">
                  <p className="text-[20px] leading-[15px]">{score[1]}</p>
                  <p className="font-chakra text-[12px] leading-[15px] normal-case opacity-50">
                    {overs[1]} overs
                  </p>
                </div>
              </div>
              <div className="hidden lg:flex justify-center items-center w-full h-[40px] bg-lime text-main">
                <a href={`/matchdetail?id=${matchId}`}>Go to Match</a>
              </div>
            </div>
          </div>

          <div className="p-2 w-full h-[289px] flex flex-row justify-center items-center">
            <div className="w-full flex flex-col lg:flex-row justify-center items-center gap-2">
              {isTraderEnabled && (
                <Link className="w-full" to="/trader">
                  <div className="p-2 w-full h-[130px] lg:h-[190px] bg-mainB flex flex-row justify-between items-center">
                    <div className="flex w-full flex-col justify-start gap-1">
                      <img
                        className="w-[40px] h-[40px]"
                        src="../img/public/group.png"
                      />
                      <p className="text-[18px] lg:text-[28px] leading-[30px]">
                        Play this match <br />
                        on <span className="text-lime">trader</span>
                      </p>
                    </div>

                    <div className="h-full w-full lg:w-[75%] flex flex-row justify-end mt-[-40px]">
                      <img
                        className="w-[139px] h-[128px]"
                        src="../img/public/trader2.png"
                      />
                    </div>
                  </div>
                </Link>
              )}
              {isSquaresEnabled && squaresForLiveMatch && (
                <Link className="w-full" to="/squares">
                  <div className="p-2 w-full h-[130px] lg:h-[190px] bg-mainB flex flex-row justify-between items-center">
                    <div className="flex w-[65%] flex-col justify-start gap-1">
                      <img
                        className="w-[40px] h-[40px]"
                        src="../img/public/squares.png"
                      />
                      <p className="text-[18px] lg:text-[28px] leading-[30px]">
                        Play this match <br />
                        on <span className="text-lime">squares</span>
                      </p>
                    </div>
                    <div className="w-[45%] h-full flex flex-row justify-end mt-[-40px]">
                      <img
                        className="w-[162px] h-[84px]"
                        src="../img/public/squares3.png"
                      />
                    </div>
                  </div>
                </Link>
              )}

              <div className="lg:hidden flex justify-center items-center w-full h-[40px] bg-lime text-main">
                <a href={`/matchdetail?id=${matchId}`}>Go to Match</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
